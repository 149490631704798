@import '../../utils/variables';

$fixed-menu-height: 8rem;
$mobile-menu-width: 30rem;

$bg-color-navbar-fixed-top: darken($primary, 25%);

.navbar {
    height: $fixed-menu-height;
}
#navbar-logo {
    // padding: 1rem 0;
    img {
        @media only screen and (max-width: 1199.98px) {
            max-width: calc(#{$mobile-menu-width} - 2rem);
        }
    }
}

// xl
@media only screen and (min-width: 1200px) {

    #menu{
        opacity: 1;
        font-size: 1.4rem;
        text-shadow: none;
    }
    body.subpage .navbar .dropdown-menu.show .nav-item{
        border: 1px solid $primary !important;
    }

    .navbar {
        height: auto;
        position: fixed;
        top: 0rem;
        left: 0;
        z-index: 20;
        width: 100%;
        padding: 0;
        height: 12rem;

        transition: .6s height ease-in-out;

        &.fixed-top{
            background: rgba($bg-color-navbar-fixed-top, .9);
            height: 10rem;
            .logo{
                margin-top: 0;

                img{
                    height: 6.8rem;
                    width: auto;
                }
            }

            .dropdown-menu.show{
                .nav-item{
                    border: 1px solid $primary !important;
                }
            }

        }

        .logo{
            transition: .6s all ease-in-out;
            margin-top: 3rem;
            margin-right: 4.5rem;

            img{
                transition: .6s all ease-in-out;
                height: 11.8rem;
                width: auto;
            }
        }
    }
    #navbar-overlay {
        opacity: 0;
    }
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
    #navbar-menu {
        width: 100%;
        justify-content: space-between;
        text-align: center;
        align-items: flex-end;

        .different-item {
            display:flex;
            flex-direction: column;
            max-width: 115px;
        }
        .different-subitem {
            padding: 2.8rem 5.5rem !important;
        }

        .nav-item{
            height: 100%;
            &.dropdown{
                .nav-link{
                    &.dropdown-toggle{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        white-space: normal;

                        &::after{
                            position: absolute;
                            bottom: -1.3rem;
                            max-width: .3rem;
                            transform: scale(1.05);
                            border-top: .35em solid;
                            border-right: .35em solid transparent;
                            border-left: .35em solid transparent;
                        }
                    }
                }
                &.show{
                    position: relative;
                    .dropdown-menu{
                        border-width: 0;
                        &.show{
                            background: transparent;
                            top: 6.2rem;
							left: calc(50% - 14.15rem);
                            overflow: hidden;

                            .nav-item{
                                margin-bottom: .3rem;
                                background: white;
                                text-align: center;
                                border: 1px solid white;
                                transition: .6s all ease-in-out;

                                animation-name: dropdownMenuAnimation;
                                animation-duration: .8s;
                                animation-fill-mode: forwards;
                                opacity: 0;
                                a {
									position: relative;
                                	width: 28.3rem;
                                    &::before, &::after{
                                        content: '';
                                        position: absolute;
                                        display: block;
                                        width: 25px;
                                        height: 15px;
                                        border-color: $primary;
                                        border-width: 1px 0 0 1px;
                                        border-style: solid;
										transition: .4s all ease-in-out;
                                    }
                                    &::after{
                                        top: 3px;
                                        left: 4px;
                                        transform: scale(0);
                                    }
                                    &::before{
                                        bottom: 3px;
                                        right: 4px;
                                        transform: scale(0) rotate(180deg);
                                    }
                                    &:hover{
                                        &::after{
                                            transform: scale(1);
                                        }
                                        &::before{
                                            transform: scale(1) rotate(180deg);
                                        }
                                    }
                                }
                                &.active{
                                    a{
                                        &::after{
                                            top: 3px;
                                            left: 4px;
                                            transform: scale(1);
                                        }
                                        &::before{
                                            bottom: 3px;
                                            right: 4px;
                                            transform: scale(1) rotate(180deg);
                                        }
                                    }
                                }


                                @for $i from 1 to 10 {
                                    &:nth-child(#{$i}) {
                                        animation-delay: #{$i*0.05}s;
                                    }
                                }

                                .dropdown-item{
                                    color: $text-color;
                                    text-decoration: none;
                                    text-transform: uppercase;
                                    font-weight: 300;
                                    white-space: normal;
                                    padding: 2.8rem 2.5rem;
                                }
                                &:active{
                                    a{
                                        color: white;
                                    }
                                }
                                &:hover{
                                    border-color: $gray;
                                }
                            }
                        }
                    }
                    .nav-link{
                        &::after{
                            animation-duration: .5s;
                            animation-name: arrowMove;
                        }
                    }
                }
            }


            .nav-link{
                text-transform: uppercase;
                color: white;
                text-decoration: none;
				letter-spacing: 0.03em;
				font-weight: 300;

                &.home{
                    position: relative;
                    left: 0;
                    padding-bottom: 0;
                    .material-icons{
                        font-size: 2.6rem;
                    }
                }
			}
            &:not(.item-101){

                position: relative;

                &::before, &::after{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;

                    border-width: 1px 0 0 1px;
                    border-style: solid;
                    border-color: white;
                    opacity: 0;
                    transition: .4s all ease-in-out;
                }

                &::before{
                    top: -5px;
                    left: -5px;
                    transform: scale(0);
                }
                &::after{
                    bottom: -5px;
                    right:  -5px;
                    transform: rotate(180deg) scale(0);
                }

                &.dropdown{
                    &::after{
                        bottom: -5px;
                    }
                }
                .dropdown-menu{
                    .nav-item{
                        &::before, &::after{
                            display: none;
                        }
                    }
                }

                &.active{
                    &::before, &::after{
                        opacity: 1;
                    }
                    &::before{
                        transform: scale(1)
                    }
                    &::after{
                        transform: rotate(180deg) scale(1);
                    }
                }

                &:hover{
                    &::before, &::after{
                        opacity: 1;
                    }
                    &::before{
                        transform: scale(1)
                    }
                    &::after{
                        transform: rotate(180deg) scale(1);
                    }
                }
            }
        }
    }
}

// ===============================================================================================================================================
// ===============================================================================================================================================
// ======================================================= xs, sm, md, lg=========================================================================
// ===============================================================================================================================================
// ===============================================================================================================================================

@media only screen and (max-width: 1199.98px) {
    .navbar {
        background: transparent!important;
        width: 0;
        right: 5rem;
        position: fixed;
        top: -15px;
        z-index: $zindex-fixed;
    }
    #menu {
        position: fixed;
        top: 0;
        right: 0;
        // padding-top: $fixed-menu-height!important;
        padding-top: 0!important;
        height: 100vh!important;
        width: auto;
        width: $mobile-menu-width;
        overflow-y: auto;
        background: rgba(black, .6);

        & > * {
            min-width: 100%;
        }
	}
	&.open {
		transform: translateX(0)!important;
		transition: transform .2s linear;
	}
	&.close {
		transform: translateX(100%)!important;
		transition: transform .2s, opacity .4s;
		opacity: 0;
	}
    .transition-standard{
        transition: all 0.3s ease-in-out;
    }
    .navbar-toggler {
        // position: fixed;
        outline: none!important;
        z-index: 1;
        padding: 0;
        height: 5rem;
        width: 5rem;
        box-sizing: content-box;
        line-height: 0;
        border-radius: 50%;

        background: transparent;
        &::before, &::after{
            content: '';
            position: fixed;
            top: 0;
        }
        &::before{
            right: 30px;
            border-style: solid;
            border-color: transparent;
            border-top-color: $darkenColor;
            border-right-color: $darkenColor;
            border-top-width: 20px;
            border-right-width: 25px;
            border-bottom-width: 35px;
            border-left-width: 0px;
        }
        &::after{
            right: 0;
            height: 55px;
            width: 30px;
            background: $darkenColor;
            z-index: -1;
        }
    }
    .navbar-toggler-icon {
        background-image: none!important;
        background: transparent;
        height: 0.3rem;
        left:50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;

        &::before {
            transform: rotate(45deg);
            top: 0;
        }

        &::after {
            transform: rotate(-45deg);
            bottom: 0;
        }
        &::after,
        &::before {
            background-color: white;
            content: "";
            display: block;
            height: 0.3rem;
            position: absolute;
            width: 1.5rem;
            transform-origin: center;
            transition: .3s all ease-in-out;
        }
        &:hover, &:active{
            &::after,
            &::before {
                background-color: white;
            }
        }
    }

    .navbar-toggler.collapsed .navbar-toggler-icon {
        background: white;
        transition: all .5s ease-in-out;
        &::before, &::after {
            transition: .3s all ease-in-out;
            transform: none;
            transform: rotate(0deg);
        }
        &::before {
            top: -0.7rem;
        }
        &::after {
            bottom: -0.7rem;
        }
    }
    .navbar-toggler.collapsed {
        & ~ #menu {
            transform: translateX(#{$mobile-menu-width});
        }
        & ~ #navbar-overlay {
            opacity: 0;
            visibility: hidden;
        }
    }
    #menu.collapse {
        &:not(.show) {
          display: block !important;
        }
    }
    .collapse {
        display: block;
    }
    #navbar-overlay {
        width: 100vw;
        height: 100vh;
        background: rgba(black, 0.82);
        position: fixed;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        visibility: visible;
        z-index: -1;
    }
    #navbar-menu {
        margin-left: -1rem;
        margin-right: -1rem;
        width: calc(100% + 2rem);
        position: relative;
        .dropdown{
            .nav-link.dropdown-toggle{
                width: 100%;
            }
        }
        .nav-item {
            transition: all 0.3s ease-in-out;
            width: auto;
            .nav-link,
            .dropdown-item {
                padding: 1.1rem;
                width: auto;
                display: inline-block;
                margin-top: -0.1rem;
                color: white;
                &:hover, &:focus{
                    color: $text-color;
                }
            }
            .nav-link{
                &:hover, &:focus{
                    text-decoration: none;
                }
            }
            & + .nav-item .nav-link {
                border-top: 0.1rem solid transparent;
            }
            &.divider {
                display: none;
            }
            &.active {
                position: relative;
                &::after, &::before{
                    content: '';
                    position: absolute;
                    width: 2rem;
                    height: 1rem;
                    border-style: solid;
                    border-color: $primary;
                }
                &::after{
                    top: 0;
                    right: 0;
                    border-width: 2px 2px 0 0;
                }
                &::before{
                    border-width: 0 0 2px 2px;
                    top: 3rem;
                    left: 0;
                }
            }
            // &.active.parent {}
            .dropdown-toggle::after{
                position: absolute;
                right: 1rem;
                top: 2rem;
                transform: rotate(-90deg);
            }
            &.dropdown.show {
                z-index: 1;
                top: 0;
                width: 100%;
                height: 100%;

                .nav-item {
                    a {
                        margin-left: 4rem;
                    }
                }
                .dropdown-toggle::after {
                    transform: rotate(0deg);
                }
                .dropdown-menu {
                    height: auto;
                    padding-bottom: 1rem;
                    animation-name: dropdownShow;
                    animation-duration: 2s;
                    animation-fill-mode: forwards;
                    overflow: hidden;
                }
            }
            // .dropdown-toggle::after {
                // float: right;
                // margin-top: calc((1.5em - 0.3em)/2);
            //     transform: rotate(-90deg);
            // }
        }
        .dropdown-menu {
            padding: 0;
            margin: 0;
            border: none;
            background: transparent;
            margin-top: -0.1rem;
            height: 0;
            transition: all 0.3s ease-in-out;
            .dropdown-item {
                &.active,
                &:hover,
                &:focus {
                    color: white;
                    background: transparent;
                }
            }
            .nav-item{
                &.active{
                    width: auto;
                    display: inline-block;
                    &::before{
                        left: 4rem;
                    }
                }
                .dropdown-item{
                    &:focus, &:hover{
                        text-decoration: none;
                    }
                }
            }
            .nav-item.active .dropdown-item {
                    color: white;
                &:hover, &:focus{
                    color: $text-color;
                }
            }
        }
    }
    .collapse.navbar-collapse.close{
        font-size: $font-size-base;
        line-height: $line-height-base;
    }
}

//  Animations
@keyframes dropdownShow {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 100vh;
    }
}
.logo--mobile{
    & img{
        transform: scale(1.3);
    }
}
